import { Grid } from '@intility/bifrost-react'

import ToolsApps from './components/ToolsApps'
import useApps from '~/hooks/useApps'
import ManagementApps from './components/ManagementApps'
import OtherApps from './components/OtherApps'
import styles from './Dashboard.module.css'
import ErrorPage from '~/components/ErrorPage'

const Dashboard = () => {
  const { error } = useApps()

  if (error) {
    console.log(error)
    // TODO: Display error message to user
    return (
      <ErrorPage>
        <h2 className='bf-h1'>Unexpected error occured</h2>
        <div className='bf-p'>
          Something went wrong, please contact Intility Support.
        </div>
      </ErrorPage>
    )
  }

  return (
    <div className='bf-content'>
      <ToolsApps />

      <div className={styles.servicesContainer}>
        <Grid small={2} medium={4} gap={24} className={styles.appsGrid}>
          <ManagementApps />
          <OtherApps />
        </Grid>
      </div>
    </div>
  )
}

export default Dashboard
