import useSWRImmutable from 'swr/immutable'
import { Icon, Skeleton } from '@intility/bifrost-react'
import { faUser } from '@fortawesome/pro-solid-svg-icons'

import { authorizedFetch } from '~/auth'

const fetcher = (url: string) =>
  authorizedFetch(url)
    .then(r => {
      if (!r.ok) throw new Error('Could not fetch photo from graph')
      return r.blob()
    })
    // createObjectURL creates a DOMString containing a URL representing the blob
    .then(URL.createObjectURL)

const ProfilePicture = ({ size }: { size: number }) => {
  // The profile picture is immutable and will rarely change between refreshes
  // https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
  const { data, error, isValidating } = useSWRImmutable(
    `https://graph.microsoft.com/v1.0/me/photos/48x48/$value`,
    fetcher
  )

  if (!data && isValidating) {
    return (
      <Skeleton.Rect width={size} height={size} className='bf-nav-profile' />
    )
  }

  if (!data || error) {
    return <Icon icon={faUser} width={size} />
  }

  return (
    <img src={data} alt='' style={{ width: size }} className='bf-nav-profile' />
  )
}

export default ProfilePicture
