import { EventMessage, EventType } from '@azure/msal-browser'

import { instance } from '~/auth'

const siteId = 3884615
const hotjarVersion = 6

async function registerHotJarScript() {
  if (import.meta.env.VITE_HOTJAR_ENABLE !== 'true') return

  // prevent reporting auth code redirect to hotjar
  const account = instance.getActiveAccount()
  if (!account) {
    await waitForHandleRedirectEnd()
  }

  const hotjar = await import('@hotjar/browser')
  hotjar.default.init(siteId, hotjarVersion)
}

// alternative to instance.handleRedirectPromise()
// which should not be called when using @azure/msal-react
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2796#issuecomment-749134973
function waitForHandleRedirectEnd() {
  return new Promise<void>(resolve => {
    const callbackId = instance.addEventCallback(handleEvent)

    function handleEvent({ eventType }: EventMessage) {
      // resolve promise when redirect ended
      if (eventType === EventType.HANDLE_REDIRECT_END) {
        resolve()

        // remove callback when finished
        if (callbackId) {
          instance.removeEventCallback(callbackId)
        }
      }
    }
  })
}

export default registerHotJarScript
