import { Skeleton } from '@intility/bifrost-react'

import useApps from '~/hooks/useApps'
import AppCard, { SkeletonCard } from './AppCard'

const OtherApps = () => {
  const { activeApps, disabledApps, count, isLoading } = useApps('other')

  if (isLoading) {
    return (
      <Skeleton repeat={count}>
        <SkeletonCard />
      </Skeleton>
    )
  }

  return (
    <>
      {activeApps.map(app => (
        <AppCard key={app.id} app={app} />
      ))}
      {disabledApps.map(app => (
        <AppCard key={app.id} app={app} disabled />
      ))}
    </>
  )
}

export default OtherApps
