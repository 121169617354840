import classNames from 'classnames'
import { Skeleton } from '@intility/bifrost-react'

import { myIntilityData } from '~/data/appData'
import type { AppType } from '~/types'
import styles from './ToolsApps.module.css'
import useApps from '~/hooks/useApps'

const ToolsApps = () => {
  const { activeApps, disabledApps, count, isLoading } = useApps('tools')

  if (isLoading) {
    return (
      <>
        <div className={styles.toolsContainer}>
          <div className={styles.tools}>
            <Skeleton repeat={count + 1}>
              <div className={styles.toolsApp}>
                <Skeleton.Rect className={styles.logoContainer} />
                <Skeleton.Text width={100} className={styles.appName} />
              </div>
            </Skeleton>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={styles.toolsContainer}>
      <div className={styles.tools}>
        {activeApps.map(app => (
          <ToolsApp key={app.id} app={app} />
        ))}
        {disabledApps.map(app => (
          <ToolsApp key={app.id} app={app} disabled />
        ))}
        <ToolsApp key={myIntilityData.id} app={myIntilityData} />
      </div>
    </div>
  )
}

const ToolsApp = ({ app, disabled }: { app: AppType; disabled?: boolean }) => {
  return (
    <a
      className={classNames(styles.toolsApp, 'bf-neutral-link', {
        [styles.disabled]: disabled
      })}
      href={app.url}
    >
      <div className={styles.logoContainer}>
        <img src={app.logo} alt='' className={styles.serviceLogo} />
      </div>
      <span className={classNames(styles.appName, 'bf-neutral-link-text')}>
        {app.name}
      </span>
    </a>
  )
}

export default ToolsApps
