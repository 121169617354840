import {
  InteractionRequiredAuthError,
  InteractionType
} from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  MsalProvider,
  useMsalAuthentication
} from '@azure/msal-react'
import { PropsWithChildren, useEffect } from 'react'
import { Button } from '@intility/bifrost-react'

import { instance, loginHint } from './instance'
import ErrorPage from '~/components/ErrorPage'

const loginRequest = {
  scopes: ['.default'],
  loginHint: loginHint
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#ssosilent-example
const Template = ({ children }: PropsWithChildren) => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  )

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest)
    }
  }, [login, error])

  if (error && !(error instanceof InteractionRequiredAuthError)) {
    return (
      <ErrorPage>
        <p>{error.errorMessage}</p>
        <p>CorrelationId: {error.correlationId}</p>
        <Button onClick={() => login(InteractionType.Redirect, loginRequest)}>
          Try again
        </Button>
      </ErrorPage>
    )
  }

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
}

const Authentication = ({ children }: PropsWithChildren) => {
  return (
    <MsalProvider instance={instance}>
      <Template>{children}</Template>
    </MsalProvider>
  )
}

export default Authentication
