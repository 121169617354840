import { Configuration } from '@azure/msal-browser'

/**
 * MSAL config for the PublicClientApplication
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const config: Configuration = {
  auth: {
    clientId: '9c20ea5a-a145-4d67-bfdf-00ca59f44c0f',
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: window.location.origin
  }
}

export { config }
