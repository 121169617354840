import { AppType } from '~/types'

// ** Tools **
// Insight
// Intility eSign
// Intility GPT
// Intility SMS
// Roadmap

// ** Management **
// ApplicationPlatformManager
// Identity Manager
// SecurityCenter
// WorkplaceCenter

// ** Other **
// Inventory
// Invoices
// Manager365
// MonitoringInsight
// SustainabilityDashboard
// Webshop

export const appData: AppType[] = [
  {
    id: 'intilityGPT',
    logo: '/assets/apps/intilityGPT.svg',
    name: 'Intility GPT',
    url: 'https://gpt.intility.com',
    description:
      "Explore the power of Intility's secure and user friendly GPT chat",
    group: 'tools'
  },
  {
    id: 'eSign',
    logo: '/assets/apps/eSign.svg',
    name: 'Intility eSign',
    url: 'https://esign.intility.com/',
    description: 'Sign documents quick and easy',
    group: 'tools'
  },
  {
    id: 'intilitySMS',
    logo: '/assets/apps/intilitySMS.svg',
    name: 'Intility SMS',
    url: 'https://sms.intility.com',
    description: 'Service for simple SMS sending on behalf of your company',
    group: 'tools'
  },
  {
    id: 'insight',
    logo: '/assets/apps/insight.svg',
    name: 'Insight',
    url: 'https://insight.intility.com',
    description:
      'Variety of reports that provide insight to your IT environment',
    group: 'tools'
  },
  {
    id: 'roadmap',
    logo: '/assets/apps/roadmap.svg',
    name: 'Intility Roadmap',
    url: 'https://roadmap.intility.com',
    description: 'Overview of coming features and services from Intility',
    group: 'tools'
  },

  {
    id: 'userAdmin',
    logo: '/assets/apps/userAdmin.svg',
    name: 'Identity Manager',
    url: 'https://portal.intility.com/user',
    description: 'Manage all user accounts in your organization',
    group: 'management',
    links: [
      {
        name: 'Create new user',
        url: 'https://portal.intility.com/user/create'
      },
      {
        name: 'User overview',
        url: 'https://portal.intility.com/user'
      },
      {
        name: 'Unhandled requests',
        url: 'https://portal.intility.com/request#filter?isHandled=false'
      }
    ]
  },
  {
    id: 'applicationPlatformManager',
    logo: '/assets/apps/applicationPlatformManager.svg',
    name: 'Application Platform Manager',
    url: 'https://apm.intility.com',
    description: 'Manage all applications in your IT environment',
    group: 'management',
    links: [
      {
        name: 'Get started',
        url: 'https://apm.intility.com'
      },
      {
        name: 'CMDB',
        url: 'https://apm.intility.com/cmdb/companies/{{companyguid}}/implementations'
      },
      {
        name: 'Assets',
        url: 'https://apm.intility.com/cmdb/companies/{{companyguid}}/assets'
      }
    ]
  },
  {
    id: 'workplaceCenter',
    logo: '/assets/apps/workplaceCenter.svg',
    name: 'Workplace Center',
    url: 'https://workplace-center.intility.com',
    description: 'Overview and interaction with your workplace environment',
    group: 'management',
    links: [
      {
        name: 'Clients',
        url: 'https://workplace-center.intility.com/client-management/inventory'
      },
      {
        name: 'Applications',
        url: 'https://workplace-center.intility.com/application'
      },
      {
        name: 'Mobile devices',
        url: 'https://workplace-center.intility.com/mobile-management/inventory'
      }
    ]
  },
  {
    id: 'securityCenter',
    logo: '/assets/apps/securityCenter.svg',
    name: 'Security Center',
    url: 'https://security-center.intility.com',
    description:
      'Vulnerabilites and remedies, firewall rules and zero-day threats',
    group: 'management',
    links: [
      {
        name: 'Information hub',
        url: 'https://security-center.intility.com/publication'
      },
      {
        name: 'Security tickets',
        url: 'https://security-center.intility.com/statistics/ticket'
      },
      {
        name: 'Services',
        url: 'https://security-center.intility.com/security-options/overview'
      }
    ]
  },

  {
    id: 'webshop',
    logo: '/assets/apps/webshop.svg',
    name: 'Intility Webshop',
    url: 'https://webshop.intility.no',
    description: 'Purchase IT equipment from Intility',
    group: 'other'
  },
  {
    id: 'invoices',
    logo: '/assets/apps/invoices.svg',
    name: 'Subscriptions & Invoices',
    url: 'https://portal.intility.com/invoice',
    description: 'Overview of subscriptions and current and historic invoices',
    group: 'other'
  },
  {
    id: 'tickets',
    logo: '/assets/apps/tickets.svg',
    name: 'Tickets',
    url: 'https://portal.intility.com/ticket',
    description: 'See support tickets from users in your organization',
    group: 'other'
  },
  {
    id: 'manager365',
    logo: '/assets/apps/manager365.svg',
    name: '365 Manager',
    url: 'https://365manager.intility.com',
    description: 'Manage your cloud workplace environment',
    group: 'other'
  },
  {
    id: 'monitoringInsight',
    logo: '/assets/apps/monitoringInsight.svg',
    name: 'Monitoring Insight',
    url: 'https://monitoring.intility.com',
    description: 'Insight on all relevant alerts from our monitoring services',
    group: 'other'
  },
  {
    id: 'inventory',
    logo: '/assets/apps/inventory.svg',
    name: 'Inventory',
    url: 'https://portal.intility.com/inventory/servers',
    description: 'Overview of all servers and network equipment',
    group: 'other'
  },
  {
    id: 'sustainabilityDashboard',
    logo: '/assets/apps/sustainabilityDashboard.svg',
    name: 'Sustainability Manager',
    url: 'https://sustainability-manager.intility.com',
    description: 'Calculate sustainability impact from your whole organization',
    group: 'other'
  },
  {
    id: 'messages',
    logo: '/assets/apps/messages.svg',
    name: 'Operational Messages',
    url: 'https://portal.intility.com/report/messages',
    description: 'See messages and significant events from Intility',
    group: 'other'
  }
]

export const myIntilityData: AppType = {
  id: 'myIntility',
  logo: '/assets/apps/myIntility.svg',
  name: 'My Intility',
  url: 'https://my.intility.com',
  description: 'My Intility',
  group: 'tools'
}
