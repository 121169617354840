import useAppsAccess from '~/api/useAppsAccess'
import { appData } from '~/data/appData'
import { AppType, SectionType } from '~/types'

const useApps = (section?: SectionType) => {
  const { data: appsWithAccess, isLoading, error } = useAppsAccess()

  // TODO: swap out for live data when api is ready
  const apps: AppType[] = appData
  const sectionApps = apps.filter(app => app.group === section)

  const activeApps = sectionApps.filter(app => appsWithAccess?.includes(app.id))
  const disabledApps = sectionApps.filter(
    app => !appsWithAccess?.includes(app.id)
  )

  return {
    count: activeApps.length + disabledApps.length,
    activeApps,
    disabledApps,
    isLoading,
    error
  }
}

export default useApps
