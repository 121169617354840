import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { Grid, Icon, Skeleton } from '@intility/bifrost-react'
import classNames from 'classnames'

import useCompany from '~/api/useCompany'
import { AppType } from '~/types'
import styles from './AppCard.module.css'

const AppCard = ({ app, disabled }: { app: AppType; disabled?: boolean }) => {
  const { data: company } = useCompany()
  const hasLinks = app.links && app.links.length > 0

  return (
    <div
      className={classNames(styles.container, {
        [styles.hasLinks]: hasLinks,
        [styles.disabled]: disabled
      })}
    >
      <a
        href={app.url}
        className='bf-neutral-link'
        tabIndex={disabled ? -1 : 0}
      >
        <div className={styles.content}>
          <div className={styles.logoContainer}>
            <img src={app.logo} alt='' className={styles.serviceLogo} />
          </div>
          <Grid gap={4}>
            <span className={styles.serviceName}>{app.name}</span>
            <span className={styles.description}>{app.description}</span>
          </Grid>
        </div>
      </a>

      {hasLinks && (
        <div className={styles.links}>
          {app.links?.map(link => {
            const url = link.url.replace('{{companyguid}}', company?.id || '')
            return (
              <div key={link.url} className={styles.linkContainer}>
                <Icon icon={faArrowRight} className='bfc-base-2' />
                <a
                  href={url}
                  key={link.name}
                  className='bf-neutral-link'
                  tabIndex={disabled ? -1 : 0}
                >
                  <span className='bf-neutral-link-text'>{link.name}</span>
                </a>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const SkeletonCard = ({ hasLinks }: { hasLinks?: boolean }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Skeleton.Rect className={styles.logoContainer} />
        <Grid gap={4} style={{ width: '100%' }}>
          <span className={styles.serviceName}>
            <Skeleton.Text width='50%' />
          </span>
          <span className={styles.description}>
            <Skeleton.Text width='100%' />
            <Skeleton.Text width='100%' className='from-small' />
          </span>
        </Grid>
      </div>
      {hasLinks && (
        <div className={styles.links}>
          <Skeleton repeat={3}>
            <div className={styles.linkContainer}>
              <Icon icon={faArrowRight} className='bfc-base-2' />
              <Skeleton.Text width={200} />
            </div>
          </Skeleton>
        </div>
      )}
    </div>
  )
}

export default AppCard
export { SkeletonCard }
